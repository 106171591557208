<template>
    <div style="margin:0 auto">
        <Form ref="ycwjQueryForm" :model="formInline"  label-position="right" :label-width="150" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="33%">
                        <FormItem  label="*选择点检周期:" prop="djdate" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="month" :options="dateoptions" v-model="formInline.djdate" @on-change="onDateChange"></DatePicker>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">生产设备点检记录</label>  
        </div> 
        <div style="margin: 5 auto;" v-if="doc!=null">
            <div style="margin:0 auto;float:right">
                <Button type="warning"  @click="previewdata">打印预览</Button>
                <Button type="primary"  @click="printdata" style="margin-left: 50px;">直接打印</Button>
            </div>
            <div id="printdiv" style="margin:0 auto;margin-top:1px;clear: both;">
                <div id="contentdiv" v-if="doc !=null"  v-html="doc.doccontent"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {getPrinters,public_print,public_print_with_dir_on_one_page,public_preview_with_dir_on_one_page} from '@/zsjs/zskjprinter.js'
export default {
    name:'Scsbdj',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            dateoptions: {
                disabledDate (date) {
                    const disabledMonth = date.getMonth();
                    const disabledYear = date.getFullYear();
                    const nowYear = new Date().getFullYear();
                    const nowMonth = new Date().getMonth();

                    console.log(disabledYear+'-'+disabledMonth+'-->'+nowYear+'-'+nowMonth)
                    return (disabledYear * 12 + disabledMonth > nowYear *12 + nowMonth)
                }
            },
            formInline:{
                djdate:null
            },
            doc:null
        }
    },
    mounted(){
        //this.handleQuery();
    },
    methods:{
        onDateChange(e){
            console.log(e)
            this.formInline.djdate = e + '-01'
            this.handleQuery()
        },
        handleQuery(){
            if(this.formInline.djdate == null ||this.formInline.djdate=='' ){
                this.$Message.error({content:'请先选择点检周期！',duration:3})
                return
            }

            let postdata={
                companyid: this.companyid,
                djdate: this.formInline.djdate
            }

            this.$axios({
                method:'post',
                url:'/api/checkdept/doc/scsbdj/get',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.doc = res.data.data
                    console.log(this.doc)
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        previewdata(){
            public_preview_with_dir_on_one_page("printdiv",1,2);
        },
        printdata(){
            public_print_with_dir_on_one_page("printdiv",1,2);
        }
    },
    components:{
    }
}
</script>
<style scoped>
</style>
 